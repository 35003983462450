import useWidth from "@/helpers/widthUtil";
import { Button } from "antd";
import Image from "next/image";
import React, { FC, useState } from "react";

interface ISectionInfo {
    id: number,
    description: string,
    image: string
}
interface ILandingExperience {
    title: string,
    section_info: Array<ISectionInfo>
}

type LandingTabsProps = {
    experience: ILandingExperience
}

const LandingTabs: FC<LandingTabsProps> = ({ experience }) => {
    const { isMobile } = useWidth();
    const [activeTab, setActiveTab] = useState<number>(experience?.section_info[0]?.id);
    const TabButtons: FC = () => {
        return (
            <div className={`d-flex ${isMobile ? 'flex-wrap' : 'flex-nowrap'}`}>
                {experience?.section_info?.map((data: object | any, key: number) => (
                    <Button
                        icon={<i className={`${data?.icon} mr-2`}></i>}
                        className={`mt-2 montserrat-font-family font-size-sm 
                        ${isMobile ? 'w-100' : ''}
                        ${activeTab === data?.id ? (key % 2 === 0 ? 'btn-primary' : 'btn-danger text-white border-danger') :
                                key % 2 === 0 ? 'btn-primary' : 'btn-danger text-white border-danger'} 
                        ${(isMobile ? (key % 2 !== 0) : key !== 0) ? (!isMobile ? "ml-2" : "") : ""}`}
                        key={key}
                        onClick={() => setActiveTab(data.id)
                        }
                        style={{ opacity: activeTab === data?.id ? 1 : 0.4, borderRadius: '18px !important' }}
                    >
                        {data.title}
                    </Button>
                ))}
            </div>

        )
    }
    return experience?.section_info?.length !== 0 ? (
      <section className={`tab-section bg-light ${isMobile ? 'pt-4' : ''}`}>
        <div className="custom-container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="features-content-area">
                <h3 className={`${isMobile ? 'mt-0 mb-0' : ''} 'line-bg-secondary'`}>
                  {experience?.title.substring(0, experience?.title?.indexOf(' '))}{' '}
                  <div style={{ color: '#DE2229' }}>
                    {experience?.title.substring(
                      experience?.title?.indexOf(' ') + 1
                    )}
                  </div>
                </h3>
              </div>
              <TabButtons />
              {experience?.section_info?.map(
                (data, key) =>
                  data?.id === activeTab && (
                    <div id={`${data.id}`} key={`tab-content-${key}`}>
                      <p className="bg-light border-18 mt-3 p-3 text-dark montserrat-font-family w-auto">
                        {data.description}
                      </p>
                    </div>
                  )
              )}
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              {experience?.section_info?.map(
                (data, key) =>
                  data?.id === activeTab && (
                    <div
                      className={`d-flex justify-content-center text-center`}
                      key={key}
                    >
                      <Image
                        key={key}
                        src={`${data.image}`}
                        alt="image"
                        // layout="fill"
                        height="300px"
                        width="475px"
                        className="tranform-up-hover box-shadow-hover"
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
    ) : null;
};
export default LandingTabs;