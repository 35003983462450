import React, { memo, useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';
import useWidth from '@/helpers/widthUtil';
import CustomAntCarousel from '@/components/CustomAntCarousel';
import Image from 'next/image';

const TestimonialSection = ({ testimonials }) => {
  const { isMobile, isTablet, isLaptop, isDesktopXL } = useWidth();
  const [initialData, setInitialData] = useState({
    itemCount: isMobile ? 1 : 4,
    cols: 3,
  });

  useEffect(() => {
    isMobile && setInitialData({ itemCount: 1, cols: 12 });
    isTablet && setInitialData({ itemCount: 2, cols: 12 });
    (isLaptop || isDesktopXL) &&
      setInitialData({
        itemCount: testimonials?.length < 3 ? 2 : 3,
        cols: testimonials?.length >= 2 ? 12 : 6,
      });
  }, [isMobile, isTablet, isLaptop, isDesktopXL]);
  const count = typeof window !== 'undefined' && window?.innerWidth <= 1302 ? 3 : 4;

  const settings = {
    slidesToShow: isMobile
      ? 1
      : testimonials?.length >= count
      ? isTablet
        ? 2
        : count
      : testimonials?.length == 2
      ? 2
      : 1,
    slidesToScroll: isMobile
      ? 1
      : testimonials?.length >= count
      ? isTablet
        ? 2
        : count
      : testimonials?.length == 2
      ? 2
      : 1,
    autoplay: true,
    arrows: false,
  };

  return (
    testimonials?.length !== 0 && (
      <section className="testimonial-area">
        <div className="custom-container">
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>What our Students say?</h4>
              </div>
            </Col>
            <CustomAntCarousel customSettings={settings}>
              {testimonials?.map((data) => (
                <div
                  className={`col-md-${initialData.cols}`}
                  key={data.id + new Date().getMilliseconds() + Math.random()}
                >
                  <div className="testimonial-slider-item p-3">
                    <div className="text-center">
                      <Image
                        src={data?.image || '/images/author.png'}
                        alt="Customer Feedback"
                        width={60}
                        height={60}
                      />
                    </div>
                    <div className="mt-3 mb-3 border-bottom" />
                    <div className="p-2">
                      <p className="text-muted" style={{ fontSize: '11px' }}>
                        {data.message}
                      </p>
                    </div>
                    <div className="text-secondary text-center text-capitalize font-weight-bold font-size-sm">
                      {data.full_name}
                    </div>
                  </div>
                </div>
              ))}
            </CustomAntCarousel>
          </Row>
        </div>
      </section>
    )
  );
};

export default memo(TestimonialSection);
